import mapValues from 'lodash/mapValues'

// BEFORE YOU MAKE CHANGES
// Please help maintain documentation of what flags are in use and what aren't
// Keep the table on this page up to date when added or removing flags
// https://scentregroup.atlassian.net/l/cp/rYoRvD9R
//
// You can also use ./bin/new-feature-flag.sh to generate changes to the other files

export enum FeatureFlagName {
  NEXT_PUBLIC_FEAT_FLAG_ANALYTICS_CONSOLE = 'NEXT_PUBLIC_FEAT_FLAG_ANALYTICS_CONSOLE',
  NEXT_PUBLIC_FEAT_FLAG_CONSENT_TARGETED_ADS = 'NEXT_PUBLIC_FEAT_FLAG_CONSENT_TARGETED_ADS',
  NEXT_PUBLIC_FEAT_FLAG_SESSION_LOGGER = 'NEXT_PUBLIC_FEAT_FLAG_SESSION_LOGGER',
  NEXT_PUBLIC_FEAT_FLAG_ENABLE_CENTRE_STORES_TYPESENSE = 'NEXT_PUBLIC_FEAT_FLAG_ENABLE_CENTRE_STORES_TYPESENSE',
  NEXT_PUBLIC_FEAT_FLAG_DISABLE_LIVE_SEARCH = 'NEXT_PUBLIC_FEAT_FLAG_DISABLE_LIVE_SEARCH',
  NEXT_PUBLIC_FEAT_FLAG_PREFERRED_CENTRE = 'NEXT_PUBLIC_FEAT_FLAG_PREFERRED_CENTRE',
}

export type FeatureFlags = { [key in FeatureFlagName]: boolean }

export const DEFAULT_FEATURE_FLAGS: FeatureFlags = mapValues(
  FeatureFlagName,
  () => false
)
