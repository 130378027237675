import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { FeatureRuleSet } from '@scentregroup/feature-management'
import useLocalStorageState from 'use-local-storage-state'
import { isEqual } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { isBrowser } from '../env'
import {
  evaluateFeatureCollection,
  FeatureCollectionEvaluation,
  FeatureEvaluationContext,
} from '../lib/remote-feature-management'
import { getFeatureManagementV2 } from '../lib/get-feature-management'
import { CustomWindowEvents, RawCookieName } from '../constants'
import { useLocalUser } from './use-local-user'
import { useCountry } from '@scentregroup/shared/country'

interface StoredEvaluation {
  seed: number
  timestamp: number
  rulesets: FeatureRuleSet[]
}

const evaluateWithContext = (
  rulesets: FeatureRuleSet[],
  context: Omit<FeatureEvaluationContext, 'time.timestampUTC'>,
  seed?: number
): FeatureCollectionEvaluation<boolean> =>
  evaluateFeatureCollection<boolean>(
    rulesets,
    {
      'time.timestampUTC': Date.now(),
      ...(isBrowser()
        ? {
            'router.path': window.location.pathname,
            'router.query': window.location.search,
          }
        : {}),
      ...context,
    },
    seed
  )

export const useRemoteFeatureManagement = ():
  | FeatureCollectionEvaluation<boolean>
  | undefined => {
  const [remoteFeatures, setRemoteFeatures] = useState<
    FeatureCollectionEvaluation<boolean> | undefined
  >(undefined)

  const [savedEvaluation, setSavedEvaluation] =
    useLocalStorageState<StoredEvaluation>(
      RawCookieName.REMOTE_FEATURE_MANAGEMENT
    )

  const apollo = useApolloClient()
  const country = useCountry()
  const user = useLocalUser()

  const userIsLoggedIn = Boolean(user)

  const contextFromHooks: Omit<FeatureEvaluationContext, 'time.timestampUTC'> =
    useMemo(
      () => ({
        'user.region': country,
        'user.loggedIn': `${userIsLoggedIn}`,
      }),
      [country, userIsLoggedIn]
    )

  if (savedEvaluation) {
    const evaluation = evaluateWithContext(
      savedEvaluation.rulesets,
      contextFromHooks,
      savedEvaluation.seed
    )

    if (!isEqual(remoteFeatures, evaluation)) {
      setRemoteFeatures(evaluation)
    }
  }

  const processNewRulesets = useCallback(
    (rulesets: FeatureRuleSet[]): void => {
      const savedRulesets = savedEvaluation?.rulesets
      if (rulesets && !isEqual(rulesets, savedRulesets)) {
        const evaluation = evaluateWithContext(
          rulesets,
          contextFromHooks,
          savedEvaluation?.seed
        )

        const store = {
          seed: evaluation.seed,
          timestamp: Date.now(),
          rulesets,
        }

        setSavedEvaluation(store)
      }
    },
    [
      contextFromHooks,
      savedEvaluation?.rulesets,
      savedEvaluation?.seed,
      setSavedEvaluation,
    ]
  )

  useEffect(() => {
    const flush = (): void => {
      if (savedEvaluation) {
        setSavedEvaluation({ ...savedEvaluation, timestamp: 0 })
      }
    }
    window.addEventListener(
      CustomWindowEvents.REMOTE_FEATURE_MANAGEMENT_INVALIDATE_CACHE,
      flush
    )

    return () => {
      window.removeEventListener(
        CustomWindowEvents.REMOTE_FEATURE_MANAGEMENT_INVALIDATE_CACHE,
        flush
      )
    }
  }, [savedEvaluation, setSavedEvaluation])

  const isEvaluating = useRef(false)

  useEffect(() => {
    const oneMinuteAgo = new Date(Date.now() - 1 * 60 * 1000).getTime()
    const savedTime = savedEvaluation?.timestamp ?? 0

    if (savedTime > oneMinuteAgo || isEvaluating.current) {
      return
    }
    isEvaluating.current = true
    getFeatureManagementV2(apollo, 'WestfieldDirect')
      .then(processNewRulesets)
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        isEvaluating.current = false
      })
    // While we do use the saved rulesets as a dependancy we don't want to update
    // when they update as they're defined in here anyway
  }, [
    savedEvaluation?.timestamp,
    savedEvaluation?.seed,
    setSavedEvaluation,
    apollo,
    processNewRulesets,
  ])

  return remoteFeatures
}
