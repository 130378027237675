import { createContext, useContext } from 'react'
import { Centre } from '../../apps/website/lib/get-centre-by-slug'

import { Link } from './types/link'

import { IUserContextType } from './types/user'
import { FeatureFlags, DEFAULT_FEATURE_FLAGS } from './feature-flags'
import { FeatureCollectionEvaluation } from './lib/remote-feature-management'

export interface UserDeviceOption {
  isUsingTouch?: boolean
}

export const TimeZoneContext = createContext('timeZone')
export const CentreContext = createContext<Centre | undefined>(undefined)
export const BasicPageContext = createContext(false)

export type GetHref = () => string

export const GetHrefContext = createContext<GetHref>(() => '')
/** TODO - remove this enzyme hack and refactor in React Testing Library
 * This test is skipped as part of REACT-18 and will be part of post-delivery tracked
 * in ticket DXP-737
 * eslint-disable-next-line jest/no-disabled-tests
 **/

// TODO: currently enzyme doesn't support useContext so the testing
// will not working when we use useContext inside the react component
// once this https://github.com/airbnb/enzyme/pull/2041 is released
// we can ditch this in the bin
export const useGetHrefContext = (): GetHref =>
  useContext<GetHref>(GetHrefContext)

export const AgentInfoContext = createContext({
  agent: '',
  isIPhone: false,
  isMobile: false,
})

export const FeatureFlagsContext = createContext<
  FeatureFlags & {
    remote?: FeatureCollectionEvaluation<boolean>
  }
>(DEFAULT_FEATURE_FLAGS)

export const PromotionPositionContext = createContext<
  string | undefined | null
>(undefined)

export const LinkContext = createContext<Link>(() => null)

export const UserContext = createContext<IUserContextType>({
  user: undefined,
  loading: false,
})
export const useUser = (): IUserContextType => useContext(UserContext)

export interface AdTargetingContextType {
  centreSlug?: string | null
}

export const AdTargetingContext = createContext<AdTargetingContextType>({})

export interface IAnalyticsContext {
  context?: string
}

export const AnalyticsPropertiesContext = createContext<IAnalyticsContext>({
  context: undefined,
})
