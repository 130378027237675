import React, { useContext, createContext, FC, PropsWithChildren } from 'react'
import { NATIONAL_CONFIG } from '../config'

export const countries = ['Australia', 'New Zealand'] as const

export type Country = (typeof countries)[number]

const CountryContext = createContext<Country | null>(null)

export const CountryProvider: FC<PropsWithChildren<{ country: Country }>> = ({
  country,
  children,
}) => (
  <CountryContext.Provider value={country}>{children}</CountryContext.Provider>
)

export function countryCode(country: Country): string {
  return country === 'Australia' ? 'AU' : 'NZ'
}

export function countryHostname(country: Country): string {
  return NATIONAL_CONFIG[country === 'Australia' ? 'au' : 'nz'].hostname
}

export function isCountry(
  maybeCountry: string | undefined | null
): maybeCountry is Country {
  return (
    maybeCountry != null &&
    (countries as readonly string[]).includes(maybeCountry)
  )
}

export const useCountry = (): Country => {
  const country = useContext(CountryContext)
  if (country === null) {
    throw new Error('useCountry must be used within a <CountryProvider />')
  }
  return country
}
